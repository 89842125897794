const Youtube = () => {
  return (
    <>
      <a
        href="https://www.youtube.com/@promovilla2035"
        className="youtube_float"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Find us on youtube"
      >
        <span className="visually-hidden">Youtube</span>
        <i className="bi bi-youtube youtube-icon" aria-hidden="true" />
      </a>
    </>
  );
};

export default Youtube;
