const Facebook = () => {
  return (
    <>
      <a
        href="https://www.facebook.com/villapromo/"
        className="facebook_float"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Contact us on Facebook"
      >
        <span className="visually-hidden">Facebook</span>
        <i className="bi bi-facebook facebook-icon" aria-hidden="true" />
      </a>
    </>
  );
};

export default Facebook;
