const Instagram = () => {
  return (
    <>
      <a
        href="https://www.instagram.com/promo_villa.immo/"
        className="instagram_float"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Contact us on Instagram"
      >
        <span className="visually-hidden">Instagram</span>
        <i className="bi bi-instagram instagram-icon" aria-hidden="true" />
      </a>
    </>
  );
};

export default Instagram;
